import React from "react";
import classes from "../../css/assets/icons/toggleIcon.module.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const ToggleIcon = ({ isExpanded, onClick }) => (
  <div
    onClick={onClick}
    className={`${classes.icon} ${isExpanded ? classes.expanded : classes.collapsed}`}
  >
    {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
  </div>
);

export default ToggleIcon;
