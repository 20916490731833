import React, { useEffect, useState } from "react";
import classes from "../../../css/pages/admin/firmware-management/editFirmware.module.css";
import { useForm } from "react-hook-form";
import { FormControl, FormHelperText, OutlinedInput } from "@mui/material";
import Button from "../../../assets/buttons/Button";
import buttonClasses from "../../../css/assets/buttons/button.module.css";
import { links } from "../../../../enums/links";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { documentTitleDictionary } from "../../../../dictionary/ua/documentTitleDictionary";
import { Link, useParams } from "react-router-dom";
import ReturnButton from "../../../assets/buttons/ReturnButton";
import { firmwareApi } from "../../../../api/firmwareApi";
import { editFirmwareDictionary } from "../../../../dictionary/ua/editFirmwareDictionary";
import {
  editFirmwareErrorCode,
  editFirmwareErrorMapper,
} from "../../../../enums/errorMappers/editFirmwareErrorMapper";
import { dateTimeHelper } from "../../../../helpers/dateTimeHelper";
import { firmwareManagementDictionary } from "../../../../dictionary/ua/firmwareManagementDictionary";
import { addFirmwareErrorMapper } from "../../../../enums/errorMappers/addFirmwareErrorMapper";

function EditFirmware() {
  useDocumentTitle(
    documentTitleDictionary.editFirmwareTitle +
      " - " +
      documentTitleDictionary.mainTitle
  );
  const { id } = useParams();
  const [apiErrors, setApiErrors] = useState([]);
  const [pressed, setPressed] = useState(false);
  const [firmware, setFirmware] = useState({
    id: "",
    hardwareVersion: "",
    firmwareVersion: "",
    description: "",
    target: "",
    prjName: "",
    deviceProfile: ""
  });

  const {
    handleSubmit,
  } = useForm({
    mode: "onChange",
  });

  const setPressedClass = () => {
    return pressed === true ? "pressed" : "";
  };

  const handleMouseDown = () => {
    setPressed(true);
    document.addEventListener("mouseup", () => handleMouseUp(), { once: true });
  };

  const handleMouseUp = () => {
    setPressed(false);
  };

  const handleChange = (event) => {
    setFirmware((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const onSubmit = async () => {
    let payload = { ...firmware };

    firmwareApi
      .update(payload, links.firmwareManagementLink)
      .then((response) => {
        if (response.success) {
            setFirmware(response.data);
        } else {
          const errorsMapped = response.errors.map((e) => ({
            errorCode: e.errorCode,
            errorText: addFirmwareErrorMapper[e.errorCode],
          }));
          setApiErrors(errorsMapped);
        }
      });
  };

  const fetchFirmware = async (id) => {
    firmwareApi
      .getById(id, links.firmwareManagementLink)
      .then((response) => {
        setFirmware(response.data);
      });
  };

  useEffect(() => {
    fetchFirmware(id);
  }, [id]);

  return (
    <div className={classes["edit-firmware-wrapper"]}>
      <div>
        <form
          className={classes["firmware-details-editor-container"]}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <FormControl
              className={classes["form-control"]}
              onChange={handleChange}
            >
              <span className={classes["label-container"]}>
                <label htmlFor="hardwareVersion">
                  {firmwareManagementDictionary.hardwareVersionHeaderText}
                </label>
              </span>
              <OutlinedInput
                id="hardwareVersion"
                type="text"
                name="hardwareVersion"
                size="small"
                value={firmware.hardwareVersion}
              />
              {apiErrors.map((e) => (
                <FormHelperText key={e.errorCode}>{e.errorText}</FormHelperText>
              ))}
            </FormControl>
          </div>

          <div>
            <FormControl
              className={classes["form-control"]}
              onChange={handleChange}
            >
              <span className={classes["label-container"]}>
                <label htmlFor="firmwareVersion">
                  {firmwareManagementDictionary.firmwareVersionHeaderText}
                </label>
              </span>
              <OutlinedInput
                id="firmwareVersion"
                type="text"
                name="firmwareVersion"
                size="small"
                value={firmware.firmwareVersion}
              />
              {apiErrors.map((e) => (
                <FormHelperText key={e.errorCode}>{e.errorText}</FormHelperText>
              ))}
            </FormControl>
          </div>

          <div>
            <FormControl
              className={classes["form-control"]}
              onChange={handleChange}
            >
              <span className={classes["label-container"]}>
                <label htmlFor="clientId">
                  {firmwareManagementDictionary.clientIdHeaderText}
                </label>
              </span>
              <OutlinedInput
                id="clientId"
                type="text"
                name="clientId"
                size="small"
                value={firmware.clientId}
              />
              {apiErrors.map((e) => (
                <FormHelperText key={e.errorCode}>{e.errorText}</FormHelperText>
              ))}
            </FormControl>
          </div>

          <div>
            <FormControl
              className={classes["form-control"]}
              onChange={handleChange}
            >
              <span className={classes["label-container"]}>
                <label htmlFor="espIdf">
                  {firmwareManagementDictionary.espIdfHeaderText}
                </label>
              </span>
              <OutlinedInput
                id="espIdf"
                type="text"
                name="espIdf"
                size="small"
                value={firmware.espIdf}
              />
              {apiErrors.map((e) => (
                <FormHelperText key={e.errorCode}>{e.errorText}</FormHelperText>
              ))}
            </FormControl>
          </div>

          <div>
            <FormControl
              className={classes["form-control"]}
              onChange={handleChange}
            >
              <span className={classes["label-container"]}>
                <label htmlFor="target">
                  {firmwareManagementDictionary.targetHeaderText}
                </label>
              </span>
              <OutlinedInput
                id="target"
                type="text"
                name="target"
                size="small"
                value={firmware.target}
              />
              {apiErrors.map((e) => (
                <FormHelperText key={e.errorCode}>{e.errorText}</FormHelperText>
              ))}
            </FormControl>
          </div>

          <div>
            <FormControl
              className={classes["form-control"]}
              onChange={handleChange}
            >
              <span className={classes["label-container"]}>
                <label htmlFor="prjName">
                  {firmwareManagementDictionary.prjNameHeaderText}
                </label>
              </span>
              <OutlinedInput
                id="prjName"
                type="text"
                name="prjName"
                size="small"
                value={firmware.prjName}
              />
              {apiErrors.map((e) => (
                <FormHelperText key={e.errorCode}>{e.errorText}</FormHelperText>
              ))}
            </FormControl>
          </div>

          <div>
            <FormControl
              className={classes["form-control"]}
              onChange={handleChange}
            >
              <span className={classes["label-container"]}>
                <label htmlFor="compileDate">
                  {firmwareManagementDictionary.compileDateHeaderText}
                </label>
              </span>
              <OutlinedInput
                id="compileDate"
                type="text"
                name="compileDate"
                size="small"
                value={dateTimeHelper.getLocalDateTime(firmware.compileDate, {
                  hour: "2-digit",
                  minute: "2-digit",
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })}
              />
              {apiErrors.map((e) => (
                <FormHelperText key={e.errorCode}>{e.errorText}</FormHelperText>
              ))}
            </FormControl>
          </div>

          <div>
            <FormControl
              className={classes["form-control"]}
              onChange={handleChange}
            >
              <span className={classes["label-container"]}>
                <label htmlFor="fileLink">
                  {firmwareManagementDictionary.fileLinkHeaderText}
                </label>
              </span>
              <OutlinedInput
                id="fileLink"
                type="text"
                name="fileLink"
                size="small"
                value={firmware.fileLink}
              />
              {apiErrors.map((e) => (
                <FormHelperText key={e.errorCode}>{e.errorText}</FormHelperText>
              ))}
            </FormControl>
          </div>

          <div>
            <FormControl
              className={classes["form-control"]}
              onChange={handleChange}
            >
              <span className={classes["label-container"]}>
                <label htmlFor="description">
                  {firmwareManagementDictionary.descriptionHeaderText}
                </label>
              </span>
              <OutlinedInput
                id="description"
                type="text"
                name="description"
                size="small"
                value={firmware.description}
              />
              {apiErrors.map((e) => (
                <FormHelperText key={e.errorCode}>{e.errorText}</FormHelperText>
              ))}
            </FormControl>
          </div>

          <div>
            <Button
              className={`${buttonClasses["edit-firmware-button"]} ${
                buttonClasses[setPressedClass()]
              }`}
              disabled={false}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
            >
              {editFirmwareDictionary.editFirmwareButtonText}
            </Button>
          </div>
        </form>

        <Link
          className={classes["return-to-firmware-management-link"]}
          to={links.firmwaremanagementPath}
        >
          <ReturnButton>
            {editFirmwareDictionary.returnToFirmwareManagement}
          </ReturnButton>
        </Link>
      </div>
    </div>
  );
}

export default EditFirmware;
