import React, { useEffect, useState } from "react";
import classes from "../../css/pages/devices/editDevice.module.css";
import { useForm } from "react-hook-form";
import { FormControl, FormHelperText, MenuItem, OutlinedInput, Select } from "@mui/material";
import Button from "../../assets/buttons/Button";
import buttonClasses from "../../css/assets/buttons/button.module.css";
import { links } from "../../../enums/links";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { documentTitleDictionary } from "../../../dictionary/ua/documentTitleDictionary";
import { Link, useParams } from "react-router-dom";
import ReturnButton from "../../assets/buttons/ReturnButton";
import { deviceApi } from "../../../api/deviceApi";
import { editDeviceDictionary } from "../../../dictionary/ua/editDeviceDictionary";
import { editDeviceErrorCode, editDeviceErrorMapper } from "../../../enums/errorMappers/editDeviceErrorMapper";
import { deviceProfile } from "../../../enums/deviceProfile";
import { addDeviceDictionary } from "../../../dictionary/ua/addDeviceDictionary";
import ImitatorProfile from "../../assets/dropdowns/profiles/ImitatorProfile";
import RemoteSwitchProfile from "../../assets/dropdowns/profiles/RemoteSwitchProfile";
import GsmGateProfile from "../../assets/dropdowns/profiles/GsmGateProfile";

function EditDevice() {
  useDocumentTitle(documentTitleDictionary.editDeviceTitle + " - " + documentTitleDictionary.mainTitle);
  const { id } = useParams();
  const [apiErrors, setApiErrors] = useState([]);
  const [pressed, setPressed] = useState(false);
  const [device, setDevice] = useState({
    id: "",
    name: "",
    profile: ""
  });

  const {
    handleSubmit,
  } = useForm({
    mode: "onChange",
  });

  const setPressedClass = () => {
    return pressed === true ? "pressed" : "";
  };

  const handleMouseDown = () => {
    setPressed(true);
    document.addEventListener("mouseup", () => handleMouseUp(), { once: true });
  };

  const handleMouseUp = () => {
    setPressed(false);
  };

  const handleChange = (event) => {
    setDevice((prevState) => {
        return {
          ...prevState,
          [event.target.name]: event.target.value
        };
      });
  };

  const onSubmit = async () => {
    let payload = {
        id: device.id,
        name: device.name,
        profile: device.profile
      };
  
      deviceApi.update(payload, links.deviceListLink).then((response) => {
        if (!response.success) {
          setApiErrors([
            {
              errorCode: editDeviceErrorCode.FailedToUpdateDevice,
              errorText:
                editDeviceErrorMapper[
                  editDeviceErrorMapper.FailedToUpdateDevice
                ],
            },
          ]);
        }
      });
  };

  const fetchDevice = async (id) => {
    deviceApi
      .getById(id, links.deviceListLink)
      .then((response) => {
        setDevice(response.data);
      })
      .catch(function (error) {
        console.error("Firmware update failed:", error);
      });
  };

  useEffect(() => {
    const fetch = async () => {
      await fetchDevice(id);
    };

    fetch();
  }, [id]);

  return (
    <div className={classes["edit-device-wrapper"]}>
      <div>
        <form className={classes["device-details-editor-container"]} onSubmit={handleSubmit(onSubmit)}>
          <div>
            <FormControl
              className={classes["form-control"]}
              onChange={handleChange}
            >
              <span className={classes["label-container"]}>
                <label htmlFor="name">
                  {editDeviceDictionary.titleLabelText}
                </label>
              </span>
              <OutlinedInput
                id="name"
                type="text"
                name="name"
                size="small"
                value={device.name}
              />
              {apiErrors.map((e) => (
                <FormHelperText key={e.errorCode}>{e.errorText}</FormHelperText>
              ))}
            </FormControl>
          </div>

          <div>
            <FormControl className={classes["form-control"]}>
              <span className={classes["label-container"]}>
                <label htmlFor="profile">
                  {editDeviceDictionary.profileLabelText}
                </label>
              </span>
              <Select
                id="profile"
                name="profile"
                className={classes["profile-select"]}
                value={device.profile}
                size="small"
                onChange={handleChange}
              >
                <MenuItem value={deviceProfile.imitator}>
                  <ImitatorProfile>
                    {addDeviceDictionary.imitatorProfileText}
                  </ImitatorProfile>
                </MenuItem>
                <MenuItem value={deviceProfile.remoteSwitch}>
                  <RemoteSwitchProfile>
                    {addDeviceDictionary.remoteSwitchProfileText}
                  </RemoteSwitchProfile>
                </MenuItem>
                <MenuItem value={deviceProfile.gsmGate}>
                  <GsmGateProfile>
                    {addDeviceDictionary.gsmGateProfileText}
                  </GsmGateProfile>
                </MenuItem>
              </Select>
            </FormControl>
          </div>

          <div>
            <Button
              className={`${buttonClasses["edit-device-button"]} ${
                buttonClasses[setPressedClass()]
              }`}
              disabled={false}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
            >
              {editDeviceDictionary.editDeviceButtonText}
            </Button>
          </div>
        </form>

        <Link
          className={classes["return-to-devices-link"]}
          to={links.deviceListPath}
        >
          <ReturnButton>{editDeviceDictionary.returnToDevices}</ReturnButton>
        </Link>
      </div>
    </div>
  );
}

export default EditDevice;
