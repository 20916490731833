import React, { useState } from "react";
import classes from "../../../css/pages/admin/firmware-management/addFirmware.module.css";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { uploadFirmwareSchema } from "../../../../validation/validationSchemas";
import { FormControl, FormHelperText, OutlinedInput } from "@mui/material";
import Button from "../../../assets/buttons/Button";
import buttonClasses from "../../../css/assets/buttons/button.module.css";
import { firmwareApi } from "../../../../api/firmwareApi";
import { firmwareManagementDictionary } from "../../../../dictionary/ua/firmwareManagementDictionary";
import { addFirmwareErrorMapper } from "../../../../enums/errorMappers/addFirmwareErrorMapper";
import dayjs from "dayjs";
import { MobileDateTimePicker } from "@mui/x-date-pickers";

function AddFirmware({ setFirmwareList }) {
  const [apiErrors, setApiErrors] = useState([]);
  const [pressed, setPressed] = useState(false);

  const { register, handleSubmit, getFieldState, reset, formState: { errors, isDirty }, control } = useForm({
    resolver: yupResolver(uploadFirmwareSchema),
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    try {
      data.compileDate = data.compileDate ? new Date(data.compileDate).toISOString() : null;
      const response = await firmwareApi.upload(data);
      if (response.success) {
        reset();
        setFirmwareList((prevState) => [...prevState, response.data]);
      } else {
        const errorsMapped = response.errors.map((e) => ({
          errorCode: e.errorCode,
          errorText: addFirmwareErrorMapper[e.errorCode],
        }));
        setApiErrors(errorsMapped);
      }
    } catch (error) {
      console.error("Firmware upload failed:", error);
    }
  };

  const setPressedClass = () => {
    return pressed === true ? "pressed" : "";
  };

  const handleMouseDown = () => {
    setPressed(true);
    document.addEventListener("mouseup", () => handleMouseUp(), { once: true });
  };

  const handleMouseUp = () => {
    setPressed(false);
  };

  const handleChange = () => {
    setApiErrors([]);
  };

  return (
    <div className={classes["add-firmware-wrapper"]}>
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <div>
          <FormControl
            className={classes["form-control"]}
            error={getFieldState("hardwareVersion").invalid}
            onChange={handleChange}
          >
            <span className={classes["label-container"]}>
              <label htmlFor="hardwareVersion">
                {firmwareManagementDictionary.hardwareVersionHeaderText}
              </label>
            </span>
            <OutlinedInput
              id="hardwareVersion"
              type="text"
              name="hardwareVersion"
              size="small"
              {...register("hardwareVersion")}
            />
            <FormHelperText>{errors.hardwareVersion?.message}</FormHelperText>
            {apiErrors.map((e) => (
              <FormHelperText key={e.errorCode}>{e.errorText}</FormHelperText>
            ))}
          </FormControl>
        </div>
        <div>
          <FormControl
            className={classes["form-control"]}
            error={getFieldState("firmwareVersion").invalid}
            onChange={handleChange}
          >
            <span className={classes["label-container"]}>
              <label htmlFor="firmwareVersion">
                {firmwareManagementDictionary.firmwareVersionHeaderText}
              </label>
            </span>
            <OutlinedInput
              id="firmwareVersion"
              type="text"
              name="firmwareVersion"
              size="small"
              {...register("firmwareVersion")}
            />
            <FormHelperText>{errors.firmwareVersion?.message}</FormHelperText>
            {apiErrors.map((e) => (
              <FormHelperText key={e.errorCode}>{e.errorText}</FormHelperText>
            ))}
          </FormControl>
        </div>
        <div>
          <FormControl
            className={classes["form-control"]}
            error={getFieldState("clientId").invalid}
            onChange={handleChange}
          >
            <span className={classes["label-container"]}>
              <label htmlFor="clientId">
                {firmwareManagementDictionary.clientIdHeaderText}
              </label>
            </span>
            <OutlinedInput
              id="clientId"
              type="text"
              name="clientId"
              size="small"
              {...register("clientId")}
            />
            <FormHelperText>{errors.clientId?.message}</FormHelperText>
            {apiErrors.map((e) => (
              <FormHelperText key={e.errorCode}>{e.errorText}</FormHelperText>
            ))}
          </FormControl>
        </div>
        <div>
          <FormControl
            className={classes["form-control"]}
            error={getFieldState("espIdf").invalid}
            onChange={handleChange}
          >
            <span className={classes["label-container"]}>
              <label htmlFor="espIdf">
                {firmwareManagementDictionary.espIdfHeaderText}
              </label>
            </span>
            <OutlinedInput
              id="espIdf"
              type="text"
              name="espIdf"
              size="small"
              {...register("espIdf")}
            />
            <FormHelperText>{errors.espIdf?.message}</FormHelperText>
            {apiErrors.map((e) => (
              <FormHelperText key={e.errorCode}>{e.errorText}</FormHelperText>
            ))}
          </FormControl>
        </div>
        <div>
          <FormControl
            className={classes["form-control"]}
            error={getFieldState("target").invalid}
            onChange={handleChange}
          >
            <span className={classes["label-container"]}>
              <label htmlFor="target">
                {firmwareManagementDictionary.targetHeaderText}
              </label>
            </span>
            <OutlinedInput
              id="target"
              type="text"
              name="target"
              size="small"
              {...register("target")}
            />
            <FormHelperText>{errors.target?.message}</FormHelperText>
            {apiErrors.map((e) => (
              <FormHelperText key={e.errorCode}>{e.errorText}</FormHelperText>
            ))}
          </FormControl>
        </div>
        <div>
          <FormControl
            className={classes["form-control"]}
            error={getFieldState("prjName").invalid}
            onChange={handleChange}
          >
            <span className={classes["label-container"]}>
              <label htmlFor="prjName">
                {firmwareManagementDictionary.prjNameHeaderText}
              </label>
            </span>
            <OutlinedInput
              id="prjName"
              type="text"
              name="prjName"
              size="small"
              {...register("prjName")}
            />
            <FormHelperText>{errors.prjName?.message}</FormHelperText>
            {apiErrors.map((e) => (
              <FormHelperText key={e.errorCode}>{e.errorText}</FormHelperText>
            ))}
          </FormControl>
        </div>
        <div>
        <FormControl
            className={classes["form-control"]}
            error={getFieldState("compileDate").invalid}
            onChange={handleChange}
          >
            <span className={classes["label-container"]}>
              <label htmlFor="compileDate">
                {firmwareManagementDictionary.compileDateHeaderText}
              </label>
            </span>
            <Controller
              name="compileDate"
              control={control}
              defaultValue={dayjs()}
              render={({ field }) => (
                <MobileDateTimePicker
                  {...field}
                  onChange={(date) => field.onChange(date)}
                  renderInput={(props) => (
                    <OutlinedInput {...props} />
                  )}
                />
              )}
            />
            <FormHelperText>{errors.compileDate?.message}</FormHelperText>
          </FormControl>
        </div>
        <div>
          <FormControl
            className={classes["form-control"]}
            error={getFieldState("fileLink").invalid}
            onChange={handleChange}
          >
            <span className={classes["label-container"]}>
              <label htmlFor="fileLink">
                {firmwareManagementDictionary.fileLinkHeaderText}
              </label>
            </span>
            <OutlinedInput
              id="fileLink"
              type="text"
              name="fileLink"
              size="small"
              {...register("fileLink")}
            />
            <FormHelperText>{errors.fileLink?.message}</FormHelperText>
            {apiErrors.map((e) => (
              <FormHelperText key={e.errorCode}>{e.errorText}</FormHelperText>
            ))}
          </FormControl>
        </div>
        <div>
          <FormControl
            className={classes["form-control"]}
            error={getFieldState("description").invalid}
            onChange={handleChange}
          >
            <span className={classes["label-container"]}>
              <label htmlFor="description">
                {firmwareManagementDictionary.descriptionHeaderText}
              </label>
            </span>
            <OutlinedInput
              id="description"
              type="text"
              name="description"
              size="small"
              {...register("description")}
            />
            <FormHelperText>{errors.description?.message}</FormHelperText>
            {apiErrors.map((e) => (
              <FormHelperText key={e.errorCode}>{e.errorText}</FormHelperText>
            ))}
          </FormControl>
        </div>

        <Button
          className={`${buttonClasses["add-firmware-button"]} ${
            buttonClasses[setPressedClass()]
          }`}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
        >
          {firmwareManagementDictionary.addFirmwareButtonLabel}
        </Button>
      </form>
    </div>
  );
}

export default AddFirmware;
